import React, { useState } from 'react';
import Panel from '../../../helpers/panel/Panel';
import EmailPayrollSelection from './Selection';
import EmailPayrollConfirmation from './Confirmation';
import EmailMessage from './Message';
import { GetPreviousWeekOfYear } from '../../../helpers/search/HelperFunctions';
import BulkEmailFinish from './Finish';
import TransportationCaller from '../../../api/internal/TransportationCaller';

const BulkEmailPDFs = () => {
  const [weekOfYear, setWeekOfYear] = useState<string>(GetPreviousWeekOfYear());
  const [selectedPayrolls, setSelectedPayrolls] = useState<
    Array<BulkEmailPayrollBoard>
  >([]);
  const [email, setEmail] = useState({
    subject: '',
    body: '',
  });

  let pages = [
    {
      title: 'Selection',
      displayedIcon: '',
      content: (
        <EmailPayrollSelection
          selectedPayrolls={selectedPayrolls}
          setSelectedPayrolls={setSelectedPayrolls}
          weekOfYear={weekOfYear}
          setWeekOfYear={setWeekOfYear}
          setEmail={setEmail}
        />
      ),
      require: [
        {
          label: 'Payroll Selections',
          FunctionBool: () => selectedPayrolls.length !== 0,
          assignedValue: () => selectedPayrolls,
          missingDesc: 'Must select at least 1 payroll to send.',
        },
      ],
    },
    {
      title: 'Email Message',
      displayedIcon: '',
      content: <EmailMessage email={email} setEmail={setEmail} />,
      require: [
        {
          label: 'Payroll Selections',
          FunctionBool: () => email.subject.trim() !== '',
          assignedValue: () => selectedPayrolls,
          missingDesc: 'Must select at least 1 payroll to send.',
        },
      ],
    },
    {
      title: 'Confirmation',
      displayedIcon: '',
      content: (
        <EmailPayrollConfirmation
          email={email}
          selectedPayrolls={selectedPayrolls}
        />
      ),
      require: [],
    },
    {
      title: 'Finish',
      displayedIcon: '',
      content: <BulkEmailFinish selectedPayrolls={selectedPayrolls} />,
      require: [],
    },
  ];

  const updateEmailSentStatus = (cashFlowId, status) => {
    setSelectedPayrolls(prevPayrolls =>
      prevPayrolls.map(p =>
        p.cashFlowId === cashFlowId ? { ...p, emailSent: status } : p,
      ),
    );
  };

  const handleSubmit = () => {
    selectedPayrolls.forEach(payroll => {
      const toRecipientAddresses = [payroll.emailAddress];
      if (payroll.secondaryEmailAddress) {
        toRecipientAddresses.push(payroll.secondaryEmailAddress);
      }

      const requestModel = {
        cashFlowId: payroll.cashFlowId,
        toRecipientAddresses: toRecipientAddresses,
        emailSubject: email.subject,
        emailBody: email.body,
      };

      setSelectedPayrolls(prevPayrolls =>
        prevPayrolls.map(p => ({ ...p, emailSent: 'loading' })),
      );

      TransportationCaller.post(
        `/Accounting/EmailPayrollDetailPDFToDriver`,
        requestModel,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .then(response => {
          if (response.status === 200) {
            updateEmailSentStatus(payroll.cashFlowId, 'success');
          } else {
            updateEmailSentStatus(payroll.cashFlowId, 'failure');
          }
        })
        .catch(() => {
          updateEmailSentStatus(payroll.cashFlowId, 'failure');
        });
    });
  };

  return (
    <Panel
      OnSubmit={handleSubmit}
      title="Bulk Email Driver Payroll"
      pages={pages}
      isCreate={false}
    />
  );
};

export default BulkEmailPDFs;
