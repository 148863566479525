import React, { useEffect, useState } from 'react';
import Input from '../../../assets/essentials/Input';
import classes from '../../../assets/Global.module.css';
import classesEmail from './BulkEmailPDFs.module.css';
import classesTable from '../../../pages/accounting/payrolls/ApplyPayrollPayments.module.css';
import Get from '../../../api/internal/Get';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import View from '../../../helpers/slab/View';
import ViewDriver from '../../views/users/driver/Driver';
import Button from '../../../assets/essentials/Button';
import { LoadingSpinner } from '../../../helpers/loadingSpinner/LoadingSpinner';

const EmailPayrollSelection = ({
  selectedPayrolls,
  setSelectedPayrolls,
  weekOfYear,
  setWeekOfYear,
  setEmail,
}) => {
  const [availablePayrolls, setAvailablePayrolls] = useState<
    Array<BulkEmailPayrollBoard>
  >([]);
  const [isEveryPayrollWithEmailSelected, setIsEveryPayrollWithEmailSelected] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    GetAvailablePayrolls(weekOfYear);
  }, []);

  useEffect(() => {
    setIsEveryPayrollWithEmailSelected(() => {
      const filteredPayrolls = availablePayrolls.filter(
        payroll => payroll.emailAddress !== null,
      );
      return (
        filteredPayrolls.length > 0 &&
        filteredPayrolls.every(payroll => payroll.isSelected)
      );
    });
  }, [selectedPayrolls, availablePayrolls]);

  function GetAvailablePayrolls(weekOfYear) {
    if (weekOfYear) {
      setIsLoading(true);
      Get(`/Accounting/GetPayrollsForBulkEmail?weekOfYear=${weekOfYear}`).then(
        response => {
          if (response) {
            const payrolls = response.data?.map(payroll => ({
              ...payroll,
              // format data
              paymentDate: DateOnlyFormatter(payroll.paymentDate),
              amount: Number(payroll.amount).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              }),
              // add properties
              isSelected: selectedPayrolls.some(
                sp => sp.cashFlowId === payroll.cashFlowId,
              ),
              emailSent: 'unsent',
            }));
            setAvailablePayrolls(payrolls);
            setIsLoading(false);
          }
        },
      );
      setEmail({
        subject: `Driver Settlement Form - Week # ${weekOfYear}`,
        body: 'Hello,\n\nPlease see your attached the driver settlement form for the week.\n\nThank you.',
      });
    }
  }

  const ToggleSelectAll = () => {
    const updatedAvailablePayrolls = availablePayrolls.map(item => {
      const payroll = {
        ...item,
        isSelected:
          !isEveryPayrollWithEmailSelected && item.emailAddress !== null,
      };
      return payroll;
    });
    setAvailablePayrolls(updatedAvailablePayrolls);
    if (isEveryPayrollWithEmailSelected) {
      setSelectedPayrolls([]);
    } else {
      // Add only payrolls with email addresses to selectedPayrolls
      const allSelectablePayrolls = availablePayrolls.filter(
        item => item.emailAddress !== null,
      );
      setSelectedPayrolls(allSelectablePayrolls);
    }
  };

  const handleCheckboxClick = (e, row) => {
    const isChecked = e.target.checked;
    // set property in availablePayrolls
    setAvailablePayrolls(prevPayrolls => {
      return prevPayrolls.map(payroll => {
        if (payroll.cashFlowId === row.cashFlowId) {
          return {
            ...payroll,
            isSelected: isChecked,
          };
        }
        return payroll;
      });
    });
    // add item to selectedPayrolls
    if (isChecked) {
      const isRowAlreadySelected = selectedPayrolls.some(
        payment => payment.cashFlowId === row.cashFlowId,
      );
      if (!isRowAlreadySelected) {
        setSelectedPayrolls([...selectedPayrolls, row]);
      }
    } else {
      setSelectedPayrolls(
        selectedPayrolls.filter(
          payment => payment.cashFlowId !== row.cashFlowId,
        ),
      );
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    const weekOfYearValue = e.target.elements.weekOfYear.value;
    setWeekOfYear(weekOfYearValue);
    setSelectedPayrolls([]);
    GetAvailablePayrolls(weekOfYearValue);
  };

  const WeekofYearForm = () => {
    return (
      <form className={classesEmail.searchForm} onSubmit={e => handleSubmit(e)}>
        <label htmlFor="weekOfYear" className={classes.attribute}>
          Week of Year
          <Input
            defaultValue={weekOfYear}
            type="number"
            name="weekOfYear"
            id="weekOfYear"
            step={1}
            min={1}
            max={52}
            required
          />
        </label>
        <Button type="submit" variant="good">
          Search
        </Button>
      </form>
    );
  };

  const TableHeader = () => {
    return (
      <thead>
        <tr>
          <th>
            <input
              id="select-all"
              type="checkbox"
              onChange={ToggleSelectAll}
              checked={isEveryPayrollWithEmailSelected}
            />
          </th>
          <th>Payment ID #</th>
          <th>Driver</th>
          <th>Date</th>
          <th>
            Email <span className={classes.required}>*</span>
          </th>
          <th>Amount</th>
        </tr>
      </thead>
    );
  };

  const TableRow = ({ row }) => {
    const getEmailDisplay = () => {
      if (!row.emailAddress) return '';

      let emailDisplay = row.emailAddress;
      if (row.secondaryEmailAddress) {
        emailDisplay += ', ' + row.secondaryEmailAddress;
      }

      return emailDisplay;
    };

    return (
      <tr key={row.cashFlowId}>
        <td>
          <input
            type="checkbox"
            name="selected"
            disabled={!row.emailAddress}
            checked={selectedPayrolls.some(
              cf => cf.cashFlowId === row.cashFlowId,
            )}
            onChange={e => handleCheckboxClick(e, row)}
          />
        </td>
        <td>{row.cashFlowId}</td>
        <td
          className={row.employeeId ? classes.clickable : ''}
          onClick={() =>
            row.employeeId &&
            View(
              <ViewDriver
                employeeId={row.employeeId}
                driverName={row.driverName}
              />,
            )
          }
        >
          {row.driverName}
        </td>
        <td>{row.paymentDate.toString()}</td>
        <td>{getEmailDisplay()}</td>
        <td>{row.amount}</td>
      </tr>
    );
  };

  return (
    <>
      <h1>Select Payrolls</h1>
      <WeekofYearForm />
      {isLoading ? (
        <LoadingSpinner />
      ) : !isLoading && availablePayrolls.length > 0 ? (
        <div className={classesTable.tableContainer}>
          <table>
            <TableHeader />
            <tbody>
              {availablePayrolls.map(row => (
                <TableRow key={row.cashFlowId} row={row} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>No results.</div>
      )}
    </>
  );
};

export default EmailPayrollSelection;
