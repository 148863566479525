import React from 'react';
import classesEmail from './BulkEmailPDFs.module.css';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { IoMdCloseCircle } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { LoadingSpinner } from '../../../helpers/loadingSpinner/LoadingSpinner';

const BulkEmailFinish = ({ selectedPayrolls }) => {
  const PayrollRow = ({ row }) => {
    return (
      <tr key={row.cashFlowId}>
        <td>{row.cashFlowId}</td>
        <td>{row.driverName}</td>
        <td>{row.emailAddress}</td>
        <td>{row.secondaryEmailAddress}</td>
        <td className={classesEmail.statusIcon}>
          {row.emailSent === 'unsent' ? (
            <FaCircle className={classesEmail.greyDot} />
          ) : row.emailSent === 'loading' ? (
            <LoadingSpinner />
          ) : row.emailSent === 'failure' ? (
            <IoMdCloseCircle className={classesEmail.failure} />
          ) : row.emailSent === 'success' ? (
            <IoMdCheckmarkCircle />
          ) : (
            <FaCircle className={classesEmail.greyDot} />
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <h1>Submit</h1>
      <div className={classesEmail.tableContainer}>
        {selectedPayrolls.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Payment ID #</th>
                <th>Driver</th>
                <th>Email</th>
                <th>Secondary Email</th>
                <th>Success</th>
              </tr>
            </thead>
            <tbody>
              {selectedPayrolls.map((row, index) => (
                <PayrollRow row={row} key={row.cashFlowId} />
              ))}
            </tbody>
          </table>
        ) : (
          <p>No payrolls selected.</p>
        )}
      </div>
    </>
  );
};

export default BulkEmailFinish;
